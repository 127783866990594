import React from 'react';
import WAppLogo from '../../images/Digital_Glyph_Green.svg'
import '../whatsappbutton/styles.css'

const WhatsappButton =() => {
    return(
        <div className="whatsapp-container">
            <a className="whatsapp-link" aria-label="Click now to book on WhatsApp" href="https://wa.me/50684001914">
                <span className="whatsapp-text">Click now to book on WhatsApp</span>
                <img alt="Click now to book on WhatsApp" src={WAppLogo} />
            </a>
        </div>
    )
};

export default WhatsappButton;