import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({ title, description, name, type }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta property="og:url" content="jacococal.com"/>
            <meta property="og:site_name" content="Jaco Cocal Beach House"></meta>
            {/* OG Tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={null} />
            <meta property="og:url" content={null} />
            <meta property="og:type" content="beachhouse" />
            <meta name='description' content={description} />
            {/* END OF OG Tags */}
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="x:creator" content={name} />
            <meta name="x:card" content={type} />
            <meta name="x:title" content={title} />
            <meta name="x:description" content={description} />
            { /* End Twitter tags */}
        </Helmet>
    )
}