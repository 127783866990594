import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar'
import Destinations from '../../api/destination'
import DestinationSidebar from './sidebar'
import Footer from '../../components/footer';
import Logo from '../../images/logo2.png'






const DestinationSinglePage = (props) => {
    const { id } = useParams()

    const destinationDetails = Destinations.find(item => item.id === id)


    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={destinationDetails.title} pagesub={'destination'} />
            <section className="wpo-destination-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="wpo-destination-single-wrap">
                                <div className="wpo-destination-single-content">
                                    <img src={destinationDetails.dSimg} alt="" />
                                    <div className="wpo-destination-single-content-des">
                                        <h2>{destinationDetails.title}</h2>
                                        <p>{destinationDetails.description}</p>
                                        {/* <div className="wpo-destination-single-sub-img">
                                            <ul>
                                                <li><img src={dimg1} alt="" /></li>
                                                <li><img src={dimg2} alt="" /></li>
                                            </ul>

                                        </div> */}
                                    </div>
                                </div>
                                {/* <p>Coming soon.</p> */}
                                {/* <Benefits /> */}
                            </div>
                        </div>
                        <DestinationSidebar destinationDetails={destinationDetails}/>
                    </div>
                </div>
            </section>
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default DestinationSinglePage;
