import React from 'react'
import { useTranslation } from "react-i18next";
// import { SocialIcon } from 'react-social-icons'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
  };

  return (
    <select value={i18n.language} onChange={handleLanguageChange}>
      <option value="en">English</option>
      <option value="es">Español</option>
    </select>
  );

};

// export default LanguageSwitcher;
const HeaderTopbar = () => {
    const { i18n } = useTranslation();
     const handleLanguageChange = (e) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);
      };

    return (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-7 col-md-8 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                {/* <li><a aria-label="Click to book on WhatsApp" href="https://wa.me/50684001914"> <img alt="Click to book on WhatsApp" src={WhatAppLogo} width={150} height={50}/></a></li> */}
                                <li><i className="fi flaticon-email"></i> gallinaepalobar@gmail.com</li>
                                <li><i className="fi flaticon-phone-call"></i> +506 8400 1914</li>
                                <li><i className="fi ti-location-pin"></i> Playa Jaco, Calle Cocal, Costa Rica</li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col col-lg-7 col-md-8 col-sm-12 col-12">
                        <div className="contact-intro">
                            <a aria-label="Chat on WhatsApp" href="https://wa.me/50684001914"> <img alt="Chat on WhatsApp" src={WhatAppLogo} /></a>
                        </div>
                    </div>
                     */}

                    <div className="col col-lg-5 col-md-4 col-sm-12 col-12">
                        <div className="contact-info">
                            <ul>
                                <li className="language">
                                <select value={i18n.language} onChange={handleLanguageChange}>
                                    <option value="en">English</option>
                                    <option value="es">Español</option>
                                    </select>
                                </li>
                                {/* <li><SocialIcon network="facebook" url="https://www.facebook.com/gallinae.palo/" /></li> */}
                                {/* <li><a href="https://www.facebook.com/gallinae.palo/"><i className="fi flaticon-facebook-app-symbol"></i></a></li> */}
                                {/* <li><Link to="/"><i className="fi flaticon-twitter"></i></Link></li> */}
                                {/* <li><Link to="/"><i className="fi flaticon-linkedin"></i></Link></li> */}
                                {/* <li><Link to="/"><i className="fi flaticon-instagram"></i></Link></li> */}
                                {/* <li><Link to="/"><i className="fi flaticon-pinterest"></i></Link></li> */}
                                {/* <li><Link to="/"><i className="fi flaticon-youtube"></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;