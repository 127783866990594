import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import api from "../../api";
import { useTranslation } from "react-i18next";
import WhatsappButton from "../whatsappbutton/whatappbutton";

const Rooms = ({ item }) => {
  const { t } = useTranslation();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const productsArray = api();
  const products = productsArray

  return (
    <div className="wpo-room-area section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-md-8">
            {/* <SectionTitleS2 MainTitle={'Our Most Populer Room'} /> */}
          </div>
        </div>
        <div className="room-wrap">
          <div className="row">
            {products.length > 0 &&
              products.map((product, pitem) => (
                <div className="col-lg-4 col-md-6 col-12" key={pitem}>
                  <div className="room-item">
                    <div className="room-img">
                      <img width={'500'} height={'500'} src={product.proImg} alt="" />
                    </div>
                    <div className="room-content">
                    <h3>${product.price} <span>/ {t('night')}</span></h3>
                      <h2><Link onClick={ClickHandler} to={`/beach-house/${product._id}`}>{t(String(product.id)+"_title")} - {t('more_information')}</Link></h2>
                      {/* <h2>{product.title}</h2> */}
                      <ul>
                        {/* <li><i className="fi flaticon-expand-arrows"></i>{product.sqm} sqm</li> */}
                        <li><i className="fi flaticon-bed"></i>{product.bedroom} {t('bed')}</li>
                        <li><i className="fi flaticon-bathtub"></i>{product.bathroom} {t('bathroom')}</li>
                      </ul>
                      
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
              {/* <div className="office-info-item">
                <div className="office-info-icon">
                  <div className="icon">
                    <i className="fi flaticon-phone-call"></i>
                  </div>
                </div>
                <div className="office-info-text">
                  <h2>{t('book_now')}</h2>
                  <p>+506 8400 1914</p>
                </div>
              </div> */}
              <WhatsappButton/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null)(Rooms);
