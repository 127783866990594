import React, { Fragment } from 'react';
import Destination from '../../components/Destination';
import Footer from '../../components/footer';
import Hero from '../../components/hero';
import Navbar from '../../components/Navbar'
import Rooms from '../../components/Rooms/Rooms';
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import SEO from '../../components/SEO';

const seo = {
    title:"Jaco Cocal Beach House - Exclusive Jaco Cocal Beach House in Jaco Beach, Costa Rica | Surf, Nightlife & Luxury", 
    description:"Escape to Jaco Cocal Beach House in Jaco Beach, Costa Rica, where luxury meets adventure. Experience the exclusive Cocal Beach House, perfect for bachelor parties and lively nightlife gatherings. Enjoy stunning ocean views, lush tropical gardens, and easy access to surfing and vibrant nightlife. Book your unforgettable stay at our beachfront retreat, ideal for relaxation, adventure, and celebrations.",
    name: "Jaco Cocal Beach House",
    type: "beachhouse"

}

const HomePage = () => {

    return (
        <div>
            <SEO
                title={seo.title}
                description={seo.description}
                name={seo.name}
                type={seo.type} />
            <Fragment>
                <Navbar topbarBlock={'wpo-header-style-2'} Logo={Logo} />
                <Hero />
                <Rooms />
                {/* <Destination /> */}
                <section className="wpo-contact-map-section">
                    <div className="wpo-contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.7946276007497!2d-84.63122592391345!3d9.612944479572992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa1c73fb3060ae7%3A0xacf24a1315b588c3!2sGallina%20E&#39;Palo!5e0!3m2!1sen!2scr!4v1715369269940!5m2!1sen!2scr"></iframe>
                    </div>
                </section>
                <Footer />
                <Scrollbar />
            </Fragment>
        </div>
    )
};

export default HomePage;