import React from 'react'
import Destinations from '../../api/destination'
import { Link } from 'react-router-dom'

const DestinationSidebar = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="col-lg-4 col-md-8">
            <div className="wpo-single-sidebar">
                <div className="wpo-service-widget widget">
                    <h2>All Experiences</h2>
                    <ul>
                        {Destinations.slice(0, 5).map((destination, Sitem) => (
                            <li key={Sitem}><Link onClick={ClickHandler} to={`/destination-single/${destination.id}`}>{destination.title}</Link></li>
                        ))}
                    </ul>
                </div>
                {/* <div className="wpo-newsletter-widget widget">
                    <h2>Newsletter</h2>
                    <p>Join 20,000 Sabscribers!</p>
                    <form className="form" onSubmit={SubmitHandler}>
                        <input type="text" placeholder="Email Address" />
                        <button type="submit">Sign Up</button>
                    </form>
                    <span>By signing up you agree to our <Link onClick={ClickHandler} to="/destination-single/1">Privecy Policy</Link></span>
                </div> */}
                {/* <div className="wpo-instagram-widget widget">
                    <h2>Instagram Shot</h2>

                    <ul>
                        <li><img src={ins1} alt="" /></li>
                        <li><img src={ins2} alt="" /></li>
                        <li><img src={ins3} alt="" /></li>
                        <li><img src={ins4} alt="" /></li>
                        <li><img src={ins5} alt="" /></li>
                        <li><img src={ins6} alt="" /></li>
                    </ul>
                </div> */}

                <div className="widget">
                    <h2>Reserve your class with {props.destinationDetails.contact} <br /> On Whatsapp</h2>
                    <div className="office-info-item">
                        <div className="office-info-icon">
                            <div className="icon">
                                <i className="fi flaticon-phone-call"></i>
                            </div>
                        </div>
                        <div className="office-info-text">
                            <p>+506 8695 9870</p>
                        </div>
                    </div>
                    <p></p>
                </div>
            </div>
        </div>

    )
}

export default DestinationSidebar;