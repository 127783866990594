// import parking from '../images/gallinadepalo/hero-1.jpeg' 
// import pool from '../images/gallinadepalo/hero-3.jpeg'
// import workoutspace from '../images/gallinadepalo/IMG_8756.jpeg'
// import freeWifi from '../images/gallinadepalo/hero-2.jpeg'
// import singleImg2 from '../images/service-single/2.jpg'
// import singleImg3 from '../images/service-single/3.jpg'
// import singleImg5 from '../images/service-single/5.jpg'
// import singleImg6 from '../images/service-single/6.jpg'



const Services = [
    {
        id: '2',
        fIcon:'flaticon-parking',
        title:'Parking Area',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        // simg1:parking,
        // ssImg:singleImg2,
    },
    {
        id: '3',
        fIcon:'flaticon-swimmer',
        title:'Swimming Pool',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        // simg1:pool,
        // ssImg:singleImg3,
    },
    {
        id: '5',
        fIcon:'flaticon-dumbell',
        title:'Exercise Space',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        // simg1:workoutspace,
        // ssImg:singleImg5,
    },
    {
        id: '6',
        fIcon:'flaticon-wifi',
        title:'Free Wifi',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        // simg1:freeWifi,
        // ssImg:singleImg6,
    },
]
export default Services;