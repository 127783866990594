import React from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";


const RoomSidebar = (props) => {
    const { t } = useTranslation();

    return (
        <div className="col-lg-4 col-12">
            <div className="blog-sidebar room-sidebar">
                <div className="widget check-widget">
                    <h3>{t('check_availability')}</h3>
                    <div className="office-info-item">
                        <div className="office-info-icon">
                            <div className="icon">
                                <i className="fi flaticon-phone-call"></i>
                            </div>
                        </div>
                        <div className="office-info-text">
                            <h2>On WhatsApp</h2>
                            <p>+506 8400 1914</p>
                        </div>
                    </div>
                </div>
                {/* <div className="widget recent-post-widget">
                    <h3>Related Posts</h3>
                    <div className="posts">
                        {blogs.map((blog, Bitem) => (
                            <div className="post" key={Bitem}>
                                <div className="img-holder">
                                    <img src={blog.screens} alt="" />
                                </div>
                                <div className="details">
                                    <h4><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.title}</Link></h4>
                                    <span className="date">{blog.create_at}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                {/* <div className="widget wpo-instagram-widget">
                    <div className="widget-title">
                        <h3>Discover Our Beah</h3>
                    </div>
                    <ul className="d-flex">
                        {products.length > 0 &&
                            products.slice(0, 6).map((product, pitem) => (
                                <li key={pitem}>
                                    <Link onClick={ClickHandler} to={`/room-single/${product.id}`}><img src={product.proImg}
                                    alt="" /></Link>
                                </li>
                            ))}
                    </ul>
                </div> */}
              
            </div>
        </div>

    )
}

export default connect(null)(RoomSidebar);