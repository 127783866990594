import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import '../../css/font-awesome.min.css';
import '../../css/themify-icons.css';
import '../../css/animate.css';
import '../../css/flaticon.css';
import '../../sass/style.scss';

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../../store/index";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../../locales/en/translation.json";
import translationES from "../../locales/es/translation.json";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { HelmetProvider } from 'react-helmet-async';

const resources = {
    en: {
      translation: translationEN,
    },
    es: {
      translation: translationES,
    },
  };

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});
i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
  });

const TheApp = () => { 
    return (   
    <HelmetProvider>
      <I18nextProvider i18n={i18next}>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <FloatingWhatsApp messageDelay={2} notificationSound={true}notificationDelay={0} notification={true} phoneNumber={"50684001914"} accountName={"GallinaDePalo"} statusMessage={"Nice to meet you!"} chatMessage={"Feel free to ask any questions about the hotel or provide your arrival date, number of guests, and preferred room type. We'll promptly confirm your reservation"} placeholder={"Hi, I'm interested in booking."} chatboxHeight={500}/>
                  <div className="App" id='scrool'>
                      <AllRoute/>
                      <ToastContainer/>
                  </div>
              </PersistGate>
          </Provider>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default TheApp;
