// import data from './data.json';
import villaa from '../images/gallinadepalo-public/villa-01.jpg'
import image2 from '../images/gallinadepalo-public/suite.jpeg'
import image3 from '../images/gallinadepalo-public/IMG_8715.jpeg'
import image4 from '../images/gallinadepalo-public/IMG_8727.jpeg'
import image5 from '../images/gallinadepalo-public/hero-2.jpeg'

import deluxVilla0 from "../images/gallinadepalo-public/IMG_8737.jpeg"
import deluxVilla1 from "../images/gallinadepalo-public/IMG_8754.jpeg"
import deluxVilla2 from "../images/gallinadepalo-public/IMG_8742.jpeg"
import deluxVilla3 from "../images/gallinadepalo-public/IMG_8745.jpeg"
import deluxVilla4 from "../images/gallinadepalo-public/IMG_8747.jpeg"
import deluxVilla5 from "../images/gallinadepalo-public/IMG_8740.jpeg"
import deluxVilla6 from "../images/gallinadepalo-public/IMG_8749.jpeg"
import deluxVilla7 from "../images/gallinadepalo-public/IMG_8750.jpeg"

import deluxSuite0 from "../images/gallinadepalo-public/IMG_8737.jpeg"
import deluxSuite1 from "../images/gallinadepalo-public/IMG_8754.jpeg"
import deluxSuite2 from "../images/gallinadepalo-public/IMG_8742.jpeg"
import deluxSuite3 from "../images/gallinadepalo-public/IMG_8745.jpeg"
import deluxSuite4 from "../images/gallinadepalo-public/IMG_8747.jpeg"
import deluxSuite5 from "../images/gallinadepalo-public/IMG_8740.jpeg"
import deluxSuite6 from "../images/gallinadepalo-public/IMG_8749.jpeg"
import deluxSuite7 from "../images/gallinadepalo-public/IMG_8750.jpeg"

import KingBedroom70 from "../images/gallinadepalo-public/IMG_8715.jpeg"
import KingBedroom71 from "../images/gallinadepalo-public/IMG_8721.jpeg"
import KingBedroom72 from "../images/gallinadepalo-public/IMG_8722.jpeg"
import KingBedroom73 from "../images/gallinadepalo-public/IMG_8724.jpeg"
import KingBedroom74 from "../images/gallinadepalo-public/IMG_8726.jpeg"
import KingBedroom75 from "../images/gallinadepalo-public/IMG_8725.jpeg"

import DeluxDoubleKing0 from "../images/gallinadepalo-public/IMG_8729.jpeg"
import DeluxDoubleKing1 from "../images/gallinadepalo-public/IMG_8728.jpeg"
import DeluxDoubleKing2 from "../images/gallinadepalo-public/IMG_8727.jpeg"
import DeluxDoubleKing3 from "../images/gallinadepalo-public/IMG_8728.jpeg"      
import DeluxDoubleKing4 from "../images/gallinadepalo-public/IMG_8730.jpeg"
import DeluxDoubleKing5 from "../images/gallinadepalo-public/IMG_8731.jpeg"

import DeluxKingRoom0 from "../images/gallinadepalo-public/IMG_8722.jpeg"
import DeluxKingRoom1 from "../images/gallinadepalo-public/IMG_8724.jpeg"
import DeluxKingRoom2 from "../images/gallinadepalo-public/IMG_8725.jpeg"
import DeluxKingRoom3 from "../images/gallinadepalo-public/IMG_8726.jpeg"

const data = [
  {
    "id": 1,
    "_id": "deluxe-villa-beachfront",
    "proImg": villaa,
    "zoom": [
      deluxVilla0,
      deluxVilla1,
      deluxVilla2,
      deluxVilla3,
      deluxVilla4,
      deluxVilla5,
      deluxVilla6,
      deluxVilla7,
    ],
    "title": "Deluxe Villa, Beachfront",
    "price": "500",
    "delPrice": "500",
    "sqm": "1000",
    "bedroom": "4 ",
    "bathroom": "4",
    "capacity": "8",
    "Children": "0",
    "description":"Located directly next to the entrance to the famous Hotel Cocal and Casino with all its amenities. A modern 4-bedroom beach house. Each bedroom is fully equipped with a king-size bed, a large shower with hot water, air conditioning, a safe, a cable TV, and Wi-Fi. A large private party area over 400 Square feet is directly on the beach with a private beach entrance and a large beachfront pool and party area. There is also a private beachfront bar on the premises. There is also a kitchen area equipped with a refrigerator, microwave and coffee maker.",
    "amenities": [
      "Refrigerator",
      "Microwave",
      "Coffee Maker",
      "Calbe TV",
      "4 Air Conditioners",    
      "Patio"
    ],
    "roomServices": [
      "Private Beach Entrance",
      "Daily Cleaning",
      "Special Swimming Pool",
      "Free Parking",
      "Free Wifi" 
    ]
  },
  {
    "id": 2,
    "_id": "deluxe-suite-beachfront",
    "proImg": image2,
    "zoom": [
      deluxSuite0,
      deluxSuite1,
      deluxSuite2,
      deluxSuite3,
      deluxSuite4,
      deluxSuite5,
      deluxSuite6,
      deluxSuite7,
    ],
    "title": "Deluxe Suite, Beachfront",
    "price": "300",
    "delPrice": "380",
    "sqm": "500",
    "bedroom": "2",
    "bathroom": "2",
    "capacity": "5",
    "Children": "0",
    "description": "Located directly next to the entrance to the famous Hotel Cocal and Casino with all its amenities. A modern 2-bedroom beach house. Each bedroom is fully equipped with a king-size bed, a large shower with hot water, air conditioning,a safe, a cable TV, and Wi-Fi. A large private party area over 400 Square feet is directly on the beach with a private beach entrance and a large beachfront pool and party area. There is also a private beachfront bar on the premises. There is also a kitchen area equipped with a refrigerator, microwave and coffee maker.",
    "amenities": [
      "Refrigerator",
      "Microwave",
      "Coffee Maker",
      "Calbe TV",
      "Air Conditioners",    
      "Patio"
    ],  "roomServices": [
      "Daily Cleaning",
      "Special Swimming Pool",
      "Free Parking",
      "Free-to-use smartphone",
      "Free Wifi",
      "Patio"
    ]
  },
  {
    "id": 0,
    "_id": "7-king-bedroom-compound",
    "proImg": image3,
    "zoom": [
      KingBedroom70,
      KingBedroom71,
      KingBedroom72,
      KingBedroom73,
      KingBedroom74,
      KingBedroom75,
    ],
    "title": "7 King Bedroom Compound",
    "price": "700",
    "delPrice": "500",
    "sqm": "1000",
    "bedroom": "7 ",
    "bathroom": "7",
    "capacity": "8",
    "Children": "0",
    "description":"A modern 7-bedroom compound with privacy consisting of 7 fully equipped seperate bedrooms each with a king-size bed, a large shower with hot water, air conditioning, a safe, a cable TV, and Wi-Fi. Located directly across from the entrance to the famous Hotel Cocal and Casino with all its amenities. A large private party area over 400 Square feet is directly on the beach with a private beach entrance and a large beachfront pool and party area. There is also a private beachfront bar on the premises. ",
    "amenities": [
      "Refrigerator",
      "Microwave",
      "Coffee Maker",
      "Calbe TV",
      "Air Conditioner",    
      "Patio"
    ],
    "roomServices": [
      "Private Beach Entrance",
      "Daily Cleaning",
      "Special Swimming Pool",
      "Free Parking",
      "Free Wifi" 
    ]
  },
  {
    "id": 3,
    "_id": "deluxe-double-king-room-beachfront",
    "proImg":image4,
    "title": "Deluxe Double King Room, Beachfront",
    "price": "150",
    "zoom": [
      DeluxDoubleKing0,
      DeluxDoubleKing1,
      DeluxDoubleKing2,
      DeluxDoubleKing3,
      DeluxDoubleKing4,
      DeluxDoubleKing5,
    ],
    "delPrice": "380",
    "sqm": "400",
    "bedroom": "2",
    "bathroom": "1",
    "capacity": "4",
    "Children": "0",
    "description":  "Located directly next to the entrance to the famous Hotel Cocal and Casino with all its amenities. A modern double king bedroom fully equipped with a 2 king-size beds, a large shower with hot water, air conditioning,a safe, a cable TV, and Wi-Fi. A large private party area over 400 Square feet is directly on the beach with a private beach entrance and a large beachfront pool and party area. There is also a private beachfront bar on the premises. ",
    "amenities": [
      "Refrigerator and water",
      "Air Conditioner Facilities",
      "2 Sets of nightwear",
      "Tables and Chairs",
      "Patio"
    ],  "roomServices": [
      "Daily Cleaning",
      "Special Swimming Pool",
      "Free Parking",
      "Free-to-use smartphone",
      "Free Wifi",
      "Patio"
    ]
  },
  {
    "id": 4,
    "_id": "deluxe-king-room-beachfront",
    "proImg":image5,
    "title": "Deluxe King Room, Beachfront",
    "price": "100",
    "zoom": [
      DeluxKingRoom0,
      DeluxKingRoom1,
      DeluxKingRoom2,
      DeluxKingRoom3,
    ],
    "delPrice": "380",
    "sqm": "300",
    "bedroom": "1",
    "bathroom": "1",
    "capacity": "2",
    "Children": "0",
    "description":   "Located directly next to the entrance to the famous Hotel Cocal and Casino with all its amenities. A modern king size bedroom fully equipped with a a king-size bed, a large shower with hot water, air conditioning,a safe, a cable TV, and Wi-Fi. A large private party area over 400 Square feet is directly on the beach with a private beach entrance and a large beachfront pool and party area. There is also a private beachfront bar on the premises. ",
    "amenities": [
      "Refrigerator and water",
      "Air Conditioner Facilities",
      "2 Sets of nightwear",
      "Tables and Chairs",
      "Patio"
    ],
    "roomServices": [
      "Daily Cleaning",
      "Special Swimming Pool",
      "Free Parking",
      "Free-to-use smartphone",
      "Free Wifi",
      "Patio"
    ]
  }
]
export default () => {
  return data;
}