import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage'
import AboutPage from '../AboutPage/AboutPage';
import DestinationPage from '../DestinationPage/DestinationPage';
import DestinationSinglePage from '../DestinationSinglePage/DestinationSinglePage';
import RoomPage from '../RoomPage';
import RoomSinglePage from '../RoomSinglePage';
import ErrorPage from '../ErrorPage/ErrorPage';
import ContactPage from '../ContactPage/ContactPage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='beach-house/:id' element={<RoomSinglePage />} />
          <Route path='room' element={<RoomPage />} />
          <Route path='destination' element={<DestinationPage />} />
          <Route path='destination-single/:id' element={<DestinationSinglePage />} />
          <Route path='404' element={<ErrorPage />} />
          <Route path='contact' element={<ContactPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
