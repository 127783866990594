import yogaImage from '../images/yoga.jpeg'

const Destinations = [
    // {
    //     id: '1',
    //     title:'Surf Lessons',
    //     subTitle:'Jaco Beach',
    //     dimg1:manuelImage,
    //     dSimg:manuelImage,
    //     price:'60',
    //     ratePeriod: 'Hour',
    //     rateAmount: '1',
    //     contact: 'Manuel',
    //     number: '+506 8695 9870',
    //     description: 'Join surf lessons with Manuel Mesen, a local professional Costa Rican surfer who has dominated the national circuit by winning every category. With a lifetime of experience starting from infancy, Manuel brings unparalleled expertise and passion to his teaching. Whether you\'re a beginner eager to catch your first wave or an experienced surfer looking to refine your skills, Manuel\'s personalized instruction ensures a fun, safe, and exhilarating experience. Dive into the vibrant surf culture of Costa Rica with a champion surfer as your guide and elevate your surfing to new heights under Manuel\'s expert tutelage.',
    // },
    {
        id: '2',
        title:'Hot Power Fusion Yoga',
        subTitle:'Jaco Beach',
        dimg1:yogaImage,
        dSimg:yogaImage,
        price:'40',
        ratePeriod: 'Hour',
        contact: 'Jesse',
        number: '+506 8695 9870',
        rateAmount: '1',
        description: 'Join Jesse Lurie for a 1-hour Hot Power Fusion yoga class on the beautiful Jaco Beach. Jesse, a 32-year-old instructor with a 200-hour Yoga Alliance certification, leads this English-language class that blends strength, balance, and mindfulness. Experience the intensity of Hot Yoga combined with breath-centered movement as you work through strength and balancing postures. Jesse\'s clear instructions and calming presence will help you synchronize your breath with your movements, creating a challenging yet soothing flow. Whether you’re a seasoned yogi or a beginner, this class will leave you feeling balanced, energized, and at peace. Enjoy the transformative power of yoga in the stunning setting of Jaco Beach.',
    },
]
export default Destinations;