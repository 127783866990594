import React from 'react';
// import WhatAppLogo from '../../images/WhatsAppButtonGreenSmall.png'
import WhatsappButton from '../whatsappbutton/whatappbutton';

const ContactpageSec = () => {
    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <p>Playa Jaco, Costa Rica, Calle Cocal.</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <p>gallinaepalobar@gmail.com</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                        <div className="contact-intro">
                                                <ul>
                                                <li><WhatsappButton/></li>
                                                    <li><i className="fi flaticon-phone-call"></i> +506 8400 1914</li>
                                                    <li><i className="fi ti-location-pin"></i> Playa Jaco, Calle Cocal, Costa Rica</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Have Any Question?</h2>
                            <h2>Call Now On WhatsApp</h2>
                            <h2>+506 8400 1914</h2>
                            <WhatsappButton/>
                        </div>
                        {/* <div className="wpo-contact-form-area">
                            <ContactForm/>
                            <div className="office-info-text">

                            </div>
                        </div> */}
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.7946276007497!2d-84.63122592391345!3d9.612944479572992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa1c73fb3060ae7%3A0xacf24a1315b588c3!2sGallina%20E&#39;Palo!5e0!3m2!1sen!2scr!4v1715369269940!5m2!1sen!2scr"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default ContactpageSec;
