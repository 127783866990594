import React from 'react'
// import { Navigation, Pagination, A11y } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation} from "react-i18next";

import WhatsappButton from '../whatsappbutton/whatappbutton';
import hero2Image from '../../images/gallinadepalo-public/villa-01.jpg';
import hero1Image from '../../images/gallinadepalo/hero-1.jpeg';
import hero3Image from '../../images/gallinadepalo/hero-3.jpeg';

const sliderItems = [
    {
        sImg:hero2Image,
    },
    {
        sImg: hero1Image,
    },
    {
        sImg: hero3Image,
    },
]

const Hero = (props) => {
    const { t } = useTranslation();
    return (
        <section className="wpo-hero-slider">
            <div className="swiper-container">
                <div className="swiper-wrapper" >
                    <div className="swiper-slide" style={{ backgroundImage: `url(${hero2Image})` }}>
                        <div className="slide-inner slide-bg-image">
                            <div className="container-fluid">
                                <div className="slide-content">
                                    <div data-swiper-parallax="300" className="slide-title">
                                        <h2>{t('hero_title')}</h2>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div className="office-info-item">
                                            <WhatsappButton/>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;