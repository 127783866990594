import React, { Fragment, useEffect, useState } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar'
import { connect } from "react-redux";
import api from "../../api";
import Footer from '../../components/footer';
import Logo from '../../images/logo2.png'
import Rooms from './rooms';
import RoomDetails from './RoomDetails';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import translationEN from "../../locales/en/translation.json";
import translationES from "../../locales/es/translation.json";
import SEO from '../../components/SEO';

const resources = {
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationES,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});


const RoomSinglePage = (props) => {
    const { t } = useTranslation()
    const { id } = useParams()

    const productsArray = api();
    const Allproduct = productsArray

    const [product, setProduct] = useState({});

    useEffect(() => {
        setProduct(Allproduct.filter(Allproduct => Allproduct._id === String(id)))
    }, []);

    const item = product[0];

    return (
        <div>
            <Fragment>
                <Navbar hclass={'wpo-header-style-2'} Logo={Logo} />
                <PageTitle pageTitle={item ? item.title : null} pagesub={'Room'} proImg={item ? item.proImg : null} />
                <div className="room-details-section">
                    {item ?
                    
                        <div className="room-details-inner">
                             <SEO
                                title={item.title}
                                description={item.description}
                                name={item.title}
                                type={'beachhouse'}
                            />
                            <div className="wpo-hotel-details-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="wpo-hotel-details-wrap">
                                                <div className="wpo-hotel-details-area">
                                                    <form className="clearfix">
                                                        <div className="details-sub">
                                                            <span>{t('beds')}</span>
                                                            <h2>{item.bedroom} {t('king_bed')}</h2>
                                                        </div>
                                                        {/* <div className="details-sub"> */}
                                                        {/* <span>BEACHHOUSE SIZE</span> */}
                                                        {/* <h2>870 sq ft / {item.sqm} sqm</h2> */}
                                                        {/* </div> */}
                                                        <div className="details-sub">
                                                            <span>{t('occupancy')}</span>
                                                            <h2>{item.capacity} {t('adults')} </h2>
                                                        </div>
                                                        <div className="details-sub">
                                                            <span>{t('bathroom')}</span>
                                                            <h2>{item.bathroom}{t('shower')}</h2>
                                                        </div>
                                                        <div className="details-sub">
                                                            <h5>{t('price')} <span>${item.price}</span> / {t('night')}</h5>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Rooms item={item} />
                            <RoomDetails item={item} />
                        </div>
                        : null}
                </div>
                <Footer />
                <Scrollbar />
            </Fragment>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
    }
};

export default connect(mapStateToProps)(RoomSinglePage);
